function car_ticker(obj){

  var ticker = {
    count:0,
  };

  var final_mileage = 18855;


  // var stage_miles = document.querySelector( obj.section_name + ' .location_map_arrow h2').getAttribute('data-distance');
  // stage_miles = stage_miles.split(" ");
  // stage_miles = stage_miles[0];
  // stage_miles = stage_miles.replace(',','');

  var stage_end = obj.end_miles;

  ticker.count = obj.start_miles;


  // console.log('stage miles: ' + stage_end, 'start miles: ' + ticker.count);

  var controller = new ScrollMagic.Controller();
  var counter_display = document.querySelector('.cayenne_counter_numbers');

  function updateHandler() {
    counter_display.innerHTML = Math.ceil(ticker.count) + ' km';
  }

  var reset_ticker = new ScrollMagic.Scene({
      triggerElement: document.querySelector(obj.next_section_name),
      duration: 10 // height of section in pixels
  })

  var car_move = new ScrollMagic.Scene({
      triggerElement: document.querySelector(obj.section_name),
      duration: 1400 // height of section in pixels
  })

  var show_ticker = new ScrollMagic.Scene({
      triggerElement: document.querySelector(obj.section_name),
      duration: 200 // height of section in pixels
  })

  var hide_ticker = new ScrollMagic.Scene({
      triggerElement: document.querySelector(obj.next_section_name),
      duration: 200, // height of section in pixels
      triggerHook: 'onEnter'
  })

  var timeline = new TimelineMax();
  var timeline_show = new TimelineMax();
  var timeline_hide = new TimelineMax();
  var timeline_reset = new TimelineMax();


  var reset_map_path = TweenMax.to( document.getElementById('js_cayenne_counter'), 1, {
              y: -700,
              ease:Linear.easeNone
          })

  var map_path = TweenMax.to( document.getElementById('js_cayenne_counter'), 1, {
              y: 0,
              ease:Linear.easeNone
          })

  var show_ticker_tween = TweenMax.to( document.querySelector('.cayenne_counter'), 1, {
              x: 0,
              ease:Linear.easeIn
          })

  var hide_ticker_tween = TweenMax.to( document.querySelector('.cayenne_counter'), 1, {
              x: -100,
              ease:Linear.easeOut
          })

  var score_tween = TweenLite.to(ticker, 1,
               { count: stage_end,
                 onUpdate: updateHandler,
                 ease: Linear.easeNone
               });

  timeline_reset
    .add(reset_map_path, 0)

  timeline
    .add(map_path, 0)
    .add(score_tween, 0)

  timeline_show
    .add(show_ticker_tween, 0)

  timeline_hide
    .add(hide_ticker_tween, 0)


  reset_ticker.addTo(controller)
  // reset_ticker.addIndicators({name: "reset"})
  reset_ticker.setTween(timeline_reset);

  car_move.addTo(controller)
  car_move.setTween(timeline);

  show_ticker.addTo(controller)
  show_ticker.setTween(timeline_show);

  hide_ticker.addTo(controller);
  hide_ticker.setTween(timeline_hide);


}


export {
  car_ticker
};
