const taxRate = 0.13;

const couponCodes = ['BLACKFRIDAY', 'FREESHIP', 'HOHOHO', 'YOLO'];

function formatPrice(price) {
	// .. do some formatting
	console.log('format');
	return formattedPrice;
}

function addTax(price) {
	console.log('add tax');
	return price * (1 + taxRate);
}

function discountPrice(price, percentage) {
	console.log('discount price');
	return price * (1 - percentage);
}


export {
	couponCodes,
	formatPrice,
	addTax,
	discountPrice
};
