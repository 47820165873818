import { helper } from './modules/helper';
import { map_animation } from './modules/stages/';
import { sticky_header } from './modules/header/sticky_header';
import { sticky_info_area } from './modules/static_info/';
import { car_ticker } from './modules/car_ticker/';
import { features_flip } from './modules/features/';
import { google_analytics } from './modules/analytics/';
var responsive = require('responsive');




// --------------------------------

const features_1 = {
  section         : document.querySelector('#features_1'),
  section_name: ".features_section_1",
  next_section_name   : '.cayenne_section_5',
  image_1: ".features_section_1 .feature_image_1",
  sticky_header_name: "#sticky_header_features_1",
}

const features_2 = {
  section         : document.querySelector('#features_2'),
  section_name: ".features_section_2",
  next_section_name   : '.cayenne_section_7',
  image_1: ".features_section_2 .feature_image_1",
  sticky_header_name: "#sticky_header_features_2",
}

const features_video_1 = {
  section         : document.querySelector('#Cayman_Challenge'),
  section_name: ".video_section_1",
  next_section_name   : '.cayenne_section_4',
  image_1: "",
  sticky_header_name: "#sticky_header_video_1",
}

const features_video_2= {
  section         : document.querySelector('#Magnus_Walker_Video'),
  section_name: ".video_section_2",
  next_section_name   : '.cayenne_section_12',
  image_1: "",
  sticky_header_name: "#sticky_header_video_2",
}

const features_video_3= {
  section         : document.querySelector('#Magnus_Walker_Video_2'),
  section_name: ".video_section_3",
  next_section_name   : '.cayenne_section_20',
  image_1: "",
  sticky_header_name: "#sticky_header_video_3",
}


features_flip(features_1);
features_flip(features_2);



// --------------------------------

const section_1 = {
  section         : document.querySelector('.cayenne_section_1'),
  motion_path     : document.getElementById('map_path_1'),
  marker_name     : '#marker_1',
  map_black_name  : '#map_black_1',
  map_white_name  : '#map_white_1',
  map_name        : '.cayenne_map_1',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_1", {align:"#marker_1"}),
  section_done    : document.querySelector('.cayenne_section_1_done'),
  section_name    : '.cayenne_section_1',
  next_section_name   : '.cayenne_section_2',
  prev_section_name   : '.cayenne_intro',
  sticky_header_name  : '#sticky_header_1',
  sticky_info         : "#section_info_sticky_1",
  miles               : 555,
  start_miles         : 0,
  end_miles           : 555
}

const section_2 = {
  section         : document.querySelector('.cayenne_section_2'),
  motion_path     : document.getElementById('map_path_2'),
  marker_name     : '#marker_2',
  map_black_name  : '#map_black_2',
  map_white_name  : '#map_white_2',
  map_name        : '.cayenne_map_2',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_2", {align:"#marker_2"}),
  section_done    : document.querySelector('.cayenne_section_2_done'),
  section_name  : '.cayenne_section_2',
  next_section_name  : '.cayenne_section_3',
  prev_section_name   : '.cayenne_section_1',
  sticky_header_name : '#sticky_header_2',
  sticky_info        : "#section_info_sticky_2",
  miles              : 1803,
  start_miles        : 555,
  end_miles          : 2385
}

const section_3 = {
  section         : document.querySelector('.cayenne_section_3'),
  motion_path     : document.getElementById('map_path_3'),
  marker_name     : '#marker_3',
  map_black_name  : '#map_black_3',
  map_white_name  : '#map_white_3',
  map_name        : '.cayenne_map_3',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_3", {align:"#marker_3"}),
  section_done    : document.querySelector('.cayenne_section_3_done'),
  section_name  : '.cayenne_section_3',
  next_section_name : '.cayenne_section_4',
  prev_section_name   : '.cayenne_section_2',
  sticky_header_name: '#sticky_header_3',
  sticky_info       : "#section_info_sticky_3",
  miles             : 1190,
  start_miles       : 2385,
  end_miles         : 3548
}

const section_4 = {
  section         : document.querySelector('.cayenne_section_4'),
  motion_path     : document.getElementById('map_path_4'),
  marker_name     : '#marker_4',
  map_black_name  : '#map_black_4',
  map_white_name  : '#map_white_4',
  map_name        : '.cayenne_map_4',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_4", {align:"#marker_4"}),
  section_done    : document.querySelector('.cayenne_section_4_done'),
  section_name    : '.cayenne_section_4',
  next_section_name : '.cayenne_section_5',
  prev_section_name   : '.cayenne_section_3',
  sticky_header_name: '#sticky_header_4',
  sticky_info         : "#section_info_sticky_4",
  miles             : 542,
  start_miles       : 3548,
  end_miles         : 4090
}

const section_5 = {
  section         : document.querySelector('.cayenne_section_5'),
  motion_path     : document.getElementById('map_path_5'),
  marker_name     : '#marker_5',
  map_black_name  : '#map_black_5',
  map_white_name  : '#map_white_5',
  map_name        : '.cayenne_map_5',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_5", {align:"#marker_5"}),
  section_done    : document.querySelector('.cayenne_section_5_done'),
  section_name    : '.cayenne_section_5',
  next_section_name : '.cayenne_section_6',
  prev_section_name   : '.cayenne_section_4',
  sticky_header_name : '#sticky_header_5',
  sticky_info        : "#section_info_sticky_5",
  miles             : 2499,
  start_miles       : 4090,
  end_miles         : 6589
}

const section_6 = {
  section         : document.querySelector('.cayenne_section_6'),
  motion_path     : document.getElementById('map_path_6'),
  marker_name     : '#marker_6',
  map_black_name  : '#map_black_6',
  map_white_name  : '#map_white_6',
  map_name        : '.cayenne_map_6',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_6", {align:"#marker_6"}),
  section_done    : document.querySelector('.cayenne_section_6_done'),
  section_name    : '.cayenne_section_6',
  next_section_name : '.cayenne_section_7',
  prev_section_name   : '.cayenne_section_5',
  sticky_header_name: '#sticky_header_6',
  sticky_info         : "#section_info_sticky_6",
  miles             : 322,
  start_miles       : 6589,
  end_miles         : 6911
}

const section_7 = {
  section         : document.querySelector('.cayenne_section_7'),
  motion_path     : document.getElementById('map_path_7'),
  marker_name     : '#marker_7',
  map_black_name  : '#map_black_7',
  map_white_name  : '#map_white_7',
  map_name        : '.cayenne_map_7',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_7", {align:"#marker_7"}),
  section_done    : document.querySelector('.cayenne_section_7_done'),
  section_name    : '.cayenne_section_7',
  next_section_name : '.cayenne_section_8',
  prev_section_name   : '.cayenne_section_6',
  sticky_header_name : '#sticky_header_7',
  sticky_info        : "#section_info_sticky_7",
  miles             : 2170,
  start_miles       : 6911,
  end_miles         : 9081
}

const section_8 = {
  section         : document.querySelector('.cayenne_section_8'),
  motion_path     : document.getElementById('map_path_8'),
  marker_name     : '#marker_8',
  map_black_name  : '#map_black_8',
  map_white_name  : '#map_white_8',
  map_name        : '.cayenne_map_8',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_8", {align:"#marker_8"}),
  section_done    : document.querySelector('.cayenne_section_8_done'),
  section_name    : '.cayenne_section_8',
  next_section_name : '.cayenne_section_9',
  prev_section_name   : '.cayenne_section_7',
  sticky_header_name: '#sticky_header_8',
  sticky_info       : "#section_info_sticky_8",
  miles             : 549,
  start_miles       : 9081,
  end_miles         : 9630
}

const section_9 = {
  section         : document.querySelector('.cayenne_section_9'),
  motion_path     : document.getElementById('map_path_9'),
  marker_name     : '#marker_9',
  map_black_name  : '#map_black_9',
  map_white_name  : '#map_white_9',
  map_name        : '.cayenne_map_9',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_9", {align:"#marker_9"}),
  section_done    : document.querySelector('.cayenne_section_9_done'),
  section_name    : '.cayenne_section_9',
  next_section_name : '.cayenne_section_10',
  prev_section_name   : '.cayenne_section_8',
  sticky_header_name: '#sticky_header_9',
  sticky_info         : "#section_info_sticky_9",
  miles             : 1021,
  start_miles       : 9630,
  end_miles         : 10651
}

const section_10 = {
  section         : document.querySelector('.cayenne_section_10'),
  motion_path     : document.getElementById('map_path_10'),
  marker_name     : '#marker_10',
  map_black_name  : '#map_black_10',
  map_white_name  : '#map_white_10',
  map_name        : '.cayenne_map_10',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_10", {align:"#marker_10"}),
  section_done    : document.querySelector('.cayenne_section_10_done'),
  section_name    : '.cayenne_section_10',
  next_section_name : '.cayenne_section_11',
  prev_section_name   : '.cayenne_section_9',
  sticky_header_name: '#sticky_header_10',
  sticky_info         : "#section_info_sticky_10",
  miles             : 1937,
  start_miles       : 10651,
  end_miles         : 12588
}

const section_11 = {
  section         : document.querySelector('.cayenne_section_11'),
  motion_path     : document.getElementById('map_path_11'),
  marker_name     : '#marker_11',
  map_black_name  : '#map_black_11',
  map_white_name  : '#map_white_11',
  map_name        : '.cayenne_map_11',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_11", {align:"#marker_11"}),
  section_done    : document.querySelector('.cayenne_section_11_done'),
  section_name    : '.cayenne_section_11',
  next_section_name : '.cayenne_section_12',
  prev_section_name   : '.cayenne_section_10',
  // next_section_name : '.footer',
  sticky_header_name: '#sticky_header_11',
  sticky_info       : "#section_info_sticky_11",
  miles             : 724,
  start_miles       : 12588,
  end_miles         : 13312
}

const section_12 = {
  section         : document.querySelector('.cayenne_section_12'),
  motion_path     : document.getElementById('map_path_12'),
  marker_name     : '#marker_12',
  map_black_name  : '#map_black_12',
  map_white_name  : '#map_white_12',
  map_name        : '.cayenne_map_12',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_12", {align:"#marker_12"}),
  section_done    : document.querySelector('.cayenne_section_12_done'),
  section_name    : '.cayenne_section_12',
  next_section_name : '.cayenne_section_13',
  prev_section_name   : '.cayenne_section_11',
  sticky_header_name: '#sticky_header_12',
  sticky_info       : "#section_info_sticky_12",
  miles             : 549,
  start_miles       : 13312,
  end_miles         : 13861
}

const section_13 = {
  section         : document.querySelector('.cayenne_section_13'),
  motion_path     : document.getElementById('map_path_13'),
  marker_name     : '#marker_13',
  map_black_name  : '#map_black_13',
  map_white_name  : '#map_white_13',
  map_name        : '.cayenne_map_13',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_13", {align:"#marker_13"}),
  section_done    : document.querySelector('.cayenne_section_13_done'),
  section_name    : '.cayenne_section_13',
  next_section_name : '.cayenne_section_14',
  prev_section_name   : '.cayenne_section_12',
  sticky_header_name: '#sticky_header_13',
  sticky_info       : "#section_info_sticky_13",
  miles             : 1054,
  start_miles       : 13861,
  end_miles         : 14915
}

const section_14 = {
  section         : document.querySelector('.cayenne_section_14'),
  motion_path     : document.getElementById('map_path_14'),
  marker_name     : '#marker_14',
  map_black_name  : '#map_black_14',
  map_white_name  : '#map_white_14',
  map_name        : '.cayenne_map_14',
  path_data       : MorphSVGPlugin.pathDataToBezier("#map_path_14", {align:"#marker_14"}),
  section_done    : document.querySelector('.cayenne_section_14_done'),
  section_name    : '.cayenne_section_14',
  next_section_name : '.cayenne_section_15',
  prev_section_name   : '.cayenne_section_13',
  sticky_header_name: '#sticky_header_14',
  sticky_info       : "#section_info_sticky_14",
  miles             : 120,
  start_miles       : 14915,
  end_miles         : 15035
}

const section_15 = {
  section           : document.querySelector('.cayenne_section_15'),
  motion_path       : document.getElementById('map_path_15'),
  marker_name       : '#marker_15',
  map_black_name    : '#map_black_15',
  map_white_name    : '#map_white_15',
  map_name          : '.cayenne_map_15',
  path_data         : MorphSVGPlugin.pathDataToBezier("#map_path_15", {align:"#marker_15"}),
  section_done      : document.querySelector('.cayenne_section_15_done'),
  section_name      : '.cayenne_section_15',
  next_section_name : '.cayenne_section_16',
  prev_section_name   : '.cayenne_section_14',
  sticky_header_name: '#sticky_header_15',
  sticky_info       : "#section_info_sticky_15",
  miles             : 859,
  start_miles       : 15035,
  end_miles         : 15894
}

const section_16 = {
  section           : document.querySelector('.cayenne_section_16'),
  motion_path       : document.getElementById('map_path_16'),
  marker_name       : '#marker_16',
  map_black_name    : '#map_black_16',
  map_white_name    : '#map_white_16',
  map_name          : '.cayenne_map_16',
  path_data         : MorphSVGPlugin.pathDataToBezier("#map_path_16", {align:"#marker_16"}),
  section_done      : document.querySelector('.cayenne_section_16_done'),
  section_name      : '.cayenne_section_16',
  next_section_name : '.cayenne_section_17',
  prev_section_name   : '.cayenne_section_15',
  sticky_header_name: '#sticky_header_16',
  sticky_info       : "#section_info_sticky_16",
  miles             : 422,
  start_miles       : 15894,
  end_miles         : 16316
}

const section_17 = {
  section           : document.querySelector('.cayenne_section_17'),
  motion_path       : document.getElementById('map_path_17'),
  marker_name       : '#marker_17',
  map_black_name    : '#map_black_17',
  map_white_name    : '#map_white_17',
  map_name          : '.cayenne_map_17',
  path_data         : MorphSVGPlugin.pathDataToBezier("#map_path_17", {align:"#marker_17"}),
  section_done      : document.querySelector('.cayenne_section_17_done'),
  section_name      : '.cayenne_section_17',
  next_section_name : '.cayenne_section_18',
  prev_section_name   : '.cayenne_section_16',
  sticky_header_name: '#sticky_header_17',
  sticky_info       : "#section_info_sticky_17",
  miles             : 376,
  start_miles       : 16316,
  end_miles         : 16692
}

const section_18 = {
  section           : document.querySelector('.cayenne_section_18'),
  motion_path       : document.getElementById('map_path_18'),
  marker_name       : '#marker_18',
  map_black_name    : '#map_black_18',
  map_white_name    : '#map_white_18',
  map_name          : '.cayenne_map_18',
  path_data         : MorphSVGPlugin.pathDataToBezier("#map_path_18", {align:"#marker_18"}),
  section_done      : document.querySelector('.cayenne_section_18_done'),
  section_name      : '.cayenne_section_18',
  next_section_name : '.cayenne_section_19',
  prev_section_name   : '.cayenne_section_17',
  sticky_header_name: '#sticky_header_18',
  sticky_info       : "#section_info_sticky_18",
  miles             : 329,
  start_miles       : 16692,
  end_miles         : 17021
}

const section_19 = {
  section           : document.querySelector('.cayenne_section_19'),
  motion_path       : document.getElementById('map_path_19'),
  marker_name       : '#marker_19',
  map_black_name    : '#map_black_19',
  map_white_name    : '#map_white_19',
  map_name          : '.cayenne_map_19',
  path_data         : MorphSVGPlugin.pathDataToBezier("#map_path_19", {align:"#marker_19"}),
  section_done      : document.querySelector('.cayenne_section_19_done'),
  section_name      : '.cayenne_section_19',
  next_section_name : '.cayenne_section_20',
  prev_section_name   : '.cayenne_section_18',
  sticky_header_name: '#sticky_header_19',
  sticky_info       : "#section_info_sticky_19",
  miles             : 308,
  start_miles       : 17021,
  end_miles         : 17329
}

const section_20 = {
  section           : document.querySelector('.cayenne_section_20'),
  motion_path       : document.getElementById('map_path_20'),
  marker_name       : '#marker_20',
  map_black_name    : '#map_black_20',
  map_white_name    : '#map_white_20',
  map_name          : '.cayenne_map_20',
  path_data         : MorphSVGPlugin.pathDataToBezier("#map_path_20", {align:"#marker_20"}),
  section_done      : document.querySelector('.cayenne_section_20_done'),
  section_name      : '.cayenne_section_20',
  next_section_name : '.footer',
  prev_section_name   : '.cayenne_section_19',
  sticky_header_name: '#sticky_header_20',
  sticky_info       : "#section_info_sticky_20",
  miles             : 1526,
  start_miles       : 17329,
  end_miles         : 18855
}

// --------------------------------

map_animation(section_1);
map_animation(section_2);
map_animation(section_3);
map_animation(section_4);
map_animation(section_5);
map_animation(section_6);
map_animation(section_7);
map_animation(section_8);
map_animation(section_9);
map_animation(section_10);
map_animation(section_11);
map_animation(section_12);
map_animation(section_13);
map_animation(section_14);
map_animation(section_15);
map_animation(section_16);
map_animation(section_17);
map_animation(section_18);
map_animation(section_19);
map_animation(section_20);


google_analytics(section_1)
google_analytics(section_2)
google_analytics(section_3)
google_analytics(section_4)
google_analytics(section_5)
google_analytics(section_6)
google_analytics(section_7)
google_analytics(section_8)
google_analytics(section_9)
google_analytics(section_10)
google_analytics(section_11)
google_analytics(section_12)
google_analytics(section_13)
google_analytics(section_14)

google_analytics(features_1);
google_analytics(features_2);

google_analytics(features_video_1);
google_analytics(features_video_2);

// trigger analytics per section



// --------------------------------


var current_window_size;

var opts = {
  checkpoints: {
    mobile: {
      width: [0, 950]
    },
    desktop: {
      width: [950, null]
    }
  }
}


var isDesktop = null;

// see if i can destroy animations
// that will be the only way to



responsive(opts, function () {

  current_window_size = this.matches;
  current_window_size = current_window_size[0];


  if(current_window_size === 'desktop'){

    if(!isDesktop){
      hero_parallax('desk');
    }

    isDesktop = true;

  }else{

    if(isDesktop || isDesktop == null){
      hero_parallax('mobile');
    }

    isDesktop = false;

  }

})





// if(isDesktop){

  //  -----------------------------

  sticky_header( section_1 )
  sticky_header( section_2 )
  sticky_header( section_3 )
  sticky_header( section_4 )
  sticky_header( section_5 )
  sticky_header( section_6 )
  sticky_header( section_7 )
  sticky_header( section_8 )
  sticky_header( section_9 )
  sticky_header( section_10 )
  sticky_header( section_11 )
  sticky_header( section_12 )
  sticky_header( section_13 )
  sticky_header( section_14 )
  sticky_header( section_15 )
  sticky_header( section_16 )
  sticky_header( section_17 )
  sticky_header( section_18 )
  sticky_header( section_19 )
  sticky_header( section_20 )


  //  -----------------------------

  car_ticker(section_1);
  car_ticker(section_2);
  car_ticker(section_3);
  car_ticker(section_4);
  car_ticker(section_5);
  car_ticker(section_6);
  car_ticker(section_7);
  car_ticker(section_8);
  car_ticker(section_9);
  car_ticker(section_10);
  car_ticker(section_11);
  car_ticker(section_12);
  car_ticker(section_13);
  car_ticker(section_14);
  car_ticker(section_15);
  car_ticker(section_16);
  car_ticker(section_17);
  car_ticker(section_18);
  car_ticker(section_19);
  car_ticker(section_20);

  //  -----------------------------

  sticky_info_area(section_1);
  sticky_info_area(section_2);
  sticky_info_area(section_3);
  sticky_info_area(section_4);
  sticky_info_area(section_5);
  sticky_info_area(section_6);
  sticky_info_area(section_7);
  sticky_info_area(section_8);
  sticky_info_area(section_9);
  sticky_info_area(section_10);
  sticky_info_area(section_11);
  sticky_info_area(section_12);
  sticky_info_area(section_13);
  sticky_info_area(section_14);
  sticky_info_area(section_15);
  sticky_info_area(section_16);
  sticky_info_area(section_17);
  sticky_info_area(section_18);
  sticky_info_area(section_19);
  sticky_info_area(section_20);

  //  -----------------------------

  sticky_header( features_1);
  sticky_header( features_2);

  sticky_header( features_video_1);
  sticky_header( features_video_2);
  sticky_header( features_video_3);

// }



const read_more_area = document.querySelector('.read_more_text');

$('.read_more_text a').on('click', read_more)

function read_more(event){
  event.preventDefault()
  $('.cayenne_intro_text .show_more').show();
  $('.read_more_text').hide()

  ga('send', {
    hitType: 'event',
    eventCategory: 'intro text',
    eventAction: 'read more',
    eventLabel: ''
  });

}


// pop up

  $('.js_img_pop_up_text').on('click', function(event){

    pop_up_img(event);

  });


  $('.js_img_pop_up').on('click', function(event){

    pop_up_img(event);

  });

  function pop_up_img(event){
    event.preventDefault();

    // $(event.target).is('.js_img_pop_up_text')

    const pop_up_holder =  document.querySelector('.pop_up_image_holder');
    var current_image = event.currentTarget.src;

    if(!current_image){

      current_image = event.currentTarget.previousElementSibling.src;

    }

    pop_up_holder.src = current_image;

    $('.cd_popup').addClass('is-visible');

    // console.log(current_image);
    $('.cd_popup').addClass('is-visible');
  }


  //close popup
  $('.cd_popup').on('click', function(event){

    event.preventDefault();

    if( $(event.target).is('.cd_popup_close') || $(event.target).is('.cd_popup') || $(event.target).is('.pop_up_image_holder') ) {
      $(this).removeClass('is-visible');
    }

  });


// main functions ################################################################################################

function hero_parallax(browser_type){



var controller = new ScrollMagic.Controller();

  // #############
  // black map fade scene in

  var scene_lax = new ScrollMagic.Scene({
      triggerElement: '.cayenne_slider',
      duration: 150,
      // offset: 400,
      triggerHook: 0.1
  })

  var timeline_lax = new TimelineMax();

if(browser_type == 'desk'){

  // console.log('desk');

  var lax_grey = TweenLite.to('.par_light_grey', 1, {
              x: '14.5%',
              ease: Sine.easeOut
          });


  var lax_car = TweenLite.to('.par_car', 1, {
              scale: 1.05,
              y: '-3%',
              ease: Sine.easeOut
          });

  var lax_map = TweenLite.to('.par_map', 1, {
              x: '-8%',
              y: '-6%',
              scale: 0.95,
              ease: Sine.easeOut
          });


  }

  if(browser_type == 'mobile'){

    // console.log('mobile');

    var lax_grey = TweenLite.to('.par_light_grey', 1, {
                x: '0%',
                ease: Sine.easeOut
            });


    var lax_car = TweenLite.to('.par_car', 1, {
                scale: 1.05,
                y: '-3%',
                ease: Sine.easeOut
            });

    var lax_map = TweenLite.to('.par_map', 1, {
                x: '8%',
                y: '6%',
                scale: 0.95,
                ease: Sine.easeOut
            });


  }



  timeline_lax
          .add(lax_grey, 0)
          .add(lax_car, 0)
          .add(lax_map, 0)

  scene_lax.addTo(controller);
  // scene_lax.addIndicators({name: "scene_lax"})
  scene_lax.setTween(timeline_lax);

  // console.log('animation called')

  return controller;

}










