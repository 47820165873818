function features_flip(obj){



  var controller = new ScrollMagic.Controller();



  var features_switch = new ScrollMagic.Scene({
      triggerElement: document.querySelectorAll(obj.image_1),
      duration: 50, // height of section in pixels
      offset: 100,
      // triggerHook: 'onEnter'
  })

  // console.log(obj)

  var timeline = new TimelineMax();





  var features_fade_img = TweenMax.to( document.querySelectorAll(obj.image_1), 1, {
              opacity: 0,
              ease:Linear.easeOut
          })



  timeline
    .add(features_fade_img, 0)



  features_switch.addTo(controller);
  // features_switch.addIndicators({name: "features"})
  features_switch.setTween(timeline);


}


export {
  features_flip
};
