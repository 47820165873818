

function sticky_info_area(dom){

    const section_height = $(dom.section_name).height();
    const animation_duration = section_height - 470;
    const controller = new ScrollMagic.Controller();


    const cayenne_section_modules = document.querySelector(dom.section_name + ' .cayenne_section_module');
    cayenne_section_modules.classList.add('sticky_scroll');

    //  ---------------------------------

    const fade_in_info = new ScrollMagic.Scene({
        triggerElement: dom.section_name,
        duration: 70, // height of section in pixels
        // triggerHook: 'onEnter',
        offset: 150
    })


    const fade_out_info = new ScrollMagic.Scene({
        triggerElement: dom.next_section_name,
        duration: 70, // height of section in pixels
        triggerHook: 'onEnter',
        offset: -150
    })

    //  ---------------------------------

    const timeline_fade_out = new TimelineMax();
    const timeline_fade_in = new TimelineMax();

    //  ---------------------------------

    const sticky_info_add_class = TweenLite.to(dom.sticky_info, 1, {
                className:"+=sticky",
                ease:Linear.easeOut
            });

    // not sure why this class isn't being removed
    const sticky_info_remove_class = TweenLite.to(dom.sticky_info, 1, {
                className:"-=sticky",
                ease:Linear.easeOut
            });

    const fade_in_tween = TweenLite.to(dom.sticky_info, 1, {
                opacity: 1,
                ease:Linear.easeOut
            });

    const fade_out_tween = TweenLite.to(dom.sticky_info, 1, {
                opacity: 0,
                ease:Linear.easeOut
            });

    //  ---------------------------------

    timeline_fade_in
            .add(fade_in_tween, 0)
            .add(sticky_info_add_class, 0)

    timeline_fade_out
            .add(fade_out_tween, 0)
            .add(sticky_info_remove_class, 0)



    //  ---------------------------------

    fade_in_info.addTo(controller)
    // fade_in_info.addIndicators({name: "fade in"})
    fade_in_info.setTween(fade_in_tween);

    fade_out_info.addTo(controller)
    // fade_out_info.addIndicators({name: "fade out"})
    fade_out_info.setTween(fade_out_tween);

}






export {
      sticky_info_area
    };
