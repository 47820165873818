const controller = new ScrollMagic.Controller();
const timeline_ga = new TimelineMax();


function google_analytics(dom){

  const ga_scene = new ScrollMagic.Scene({
      triggerElement: dom.section_name,
      duration: 0,
      offset: 0
  })


  ga_scene.addTo(controller);
  // ga_scene.addIndicators({name: "ga"})

  ga_scene.on("enter", function (event) {
    // console.log('enter for analytics', dom.section.id)

    ga('send', {
      hitType: 'event',
      eventCategory: 'section scroll',
      eventAction: 'scroll distance',
      eventLabel: dom.section.id
    });

  });


}



export {
  google_analytics
};
