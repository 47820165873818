// utility functions #############################################################################################


function map_animation(section_obj){

var section = section_obj;

section.path_object = {
  length: 0,
  pathLength: section.motion_path.getTotalLength()
}

function drawLine(motion_path, anim_obj) {
  // this offset is what makes the animation work
  motion_path.style.strokeDasharray = [ anim_obj.length, anim_obj.pathLength ].join(' ');
}

// main functions ################################################################################################

var controller = new ScrollMagic.Controller();

// section 1 #####################################################################################################
// data ############


// ############
// setup ######

section.motion_path.style.stroke = '#d5001c';
TweenLite.set(section.marker_name, { xPercent: -50, yPercent: -50});


// ##################
// map scene

var scene_map = new ScrollMagic.Scene({
    triggerElement: section.section,
    duration: 1400, // height of section in pixels
    offset: -100
})

var timeline = new TimelineMax();
var map_path = TweenMax.to( section.path_object, 1, {
            length: section.motion_path.getTotalLength(),
            onUpdate: drawLine,
            onUpdateParams: [section.motion_path, section.path_object],
            ease:Linear.easeNone
        })

var map_marker = TweenLite.to(section.marker_name, 1, {
            bezier:{ values: section.path_data,
                     type:"cubic"
            },
            ease:Linear.easeNone
        });


timeline
        .add(map_path, 0)
        .add(map_marker, 0)


scene_map.addTo(controller)
scene_map.setTween(timeline);


// #############
// black map fade scene in

var scene_map_black_overlay = new ScrollMagic.Scene({
    triggerElement: section.section,
    duration: 600 // height of section in pixels
})

var timeline_black_overlay = new TimelineMax();

var map_black = TweenLite.to(section.map_black_name, 1, {
            opacity: 0.85,
            ease:Linear.easeNone
        });

timeline_black_overlay
                  .add(map_black, 0)

scene_map_black_overlay.addTo(controller);
// scene_map_black_overlay.addIndicators({name: "map black overlay"})
scene_map_black_overlay.setTween(timeline_black_overlay);


// #############
// white map fade scene

var map_fade_in = new ScrollMagic.Scene({
    triggerElement: section.section,
    duration: 150, // height of section in pixels
    offset: -230
})

var timeline_white = new TimelineMax();

var white_fade = TweenLite.to(section.map_white_name, 1, {
            opacity: 0,
            ease:Linear.easeNone
        });


var map_fade = TweenLite.to(section.map_name, 1, {
            opacity: 1,
            ease:Linear.easeNone
        });

timeline_white
        .add(white_fade, 0)
        .add(map_fade, 0)

map_fade_in.addTo(controller)
// map_fade_in.addIndicators({name: "map white overlay"})
map_fade_in.setTween(timeline_white);


// #############
// white section map fade

var section_fade_out = new ScrollMagic.Scene({
    triggerElement: section.section,
    duration: 70, // height of section in pixels
    offset: -230
})

var timeline_section_fade_out = new TimelineMax();


var section_white_fade = TweenLite.to(section.prev_section_name + " .cayenne_section_module", 1, {
            backgroundColor:"#ffffff",
            ease:Linear.easeNone
        });


timeline_section_fade_out
        .add(section_white_fade, 0)

section_fade_out.addTo(controller)
// section_fade_out.addIndicators({name: "section_fade_out"})
section_fade_out.setTween(timeline_section_fade_out);

// ##############
// fade scene out

var map_fade_out = new ScrollMagic.Scene({
    triggerElement: section.section_done,
    duration: 150, // height of section in pixels\
    offset: -230
})

var timeline_fade = new TimelineMax();

var fade_section_opaque = TweenLite.to(section.map_white_name, 1, {
            opacity: 1,
            ease:Linear.easeNone
        });

var fade_map_out = TweenLite.to(section.map_name, 1, {
            opacity: 0,
            ease:Linear.easeNone
        });

timeline_fade
        .add(fade_section_opaque, 0)
        .add(fade_map_out, 0)

map_fade_out.addTo(controller)
// map_fade_out.addIndicators({name: "map fade"})
map_fade_out.setTween(timeline_fade);






}




export {
  map_animation
};
