// main functions ################################################################################################

var controller = new ScrollMagic.Controller();
const timeline_fade_in = new TimelineMax();




function sticky_header(dom, isMobile){
  var scene_header_1 = new ScrollMagic.Scene({
                                triggerElement: dom.sticky_header_name,
                                triggerHook: 0,
                                // duration: 300
                                })
                        .setPin(dom.sticky_header_name)
                        // .addIndicators({name: "stick header with duration"})
                        .addTo(controller);




  const fade_in_info = new ScrollMagic.Scene({
      triggerElement: dom.next_section_name,
      duration: 70,
      // triggerHook: 'onEnter',
      offset: 150
  })



  const fade_out_tween = TweenLite.to(dom.sticky_header_name, 1, {
            opacity: 0,
            ease:Linear.easeOut
        });

  timeline_fade_in
            .add(fade_out_tween, 0)


  fade_in_info.addTo(controller)
  fade_in_info.setTween(fade_out_tween);




  // if(isMobile){
  //   console.log('destroy sticky header')
  //   scene_header_1.destroy(true);
  //   controller.destroy(true);

  // }

}



export {
  sticky_header
};
